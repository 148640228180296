<template>
  <div>
    <head-layout  :head-title="'数据采集新增'"
                  :head-btn-options="headBtnOptions"
                  @head-save="headSave('save')"
                  @head-save-back="headSave('back')"
                  @head-cancel="headCancel"
                  ></head-layout>
    <div class="newZtFormBox">
      <el-form :inline="false" :model="form" label-width="120px" :rules="rules" ref="ruleForm">
        <el-row>
          <el-col :span="8">
            <el-form-item label="接口编码:" prop="infCode">
              <el-input disabled class = 'new-form-input' v-model="form.infCode" size="mini" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="接口名称:" prop="infName">
              <el-input  placeholder = '接口名称' class = 'new-form-input' v-model="form.infName" size="mini" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="表名:" prop="dataTable">
              <el-input placeholder = '表名'  class = 'new-form-input' v-model="form.dataTable" size="mini" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">

            <el-form-item label="增量字段:" prop="tsField">
              <el-input  placeholder = '增量字段'  class = 'new-form-input' v-model="form.tsField" size="mini" autocomplete="off"></el-input>
            </el-form-item>

          </el-col>
          <el-col :span="8">
            <el-form-item label="数据源:" prop="dataSource">
              <el-select  class = 'new-form-input' v-model="form.dataSource" size="mini" placeholder="请选择类型">
                <el-option v-for="(item,index) in selDat" :key="index" :label="item.sourceName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>

          </el-col>
          <el-col :span="8">
            <el-form-item label="增量时间类型:" >
              <el-select  class = 'new-form-input' v-model="form.tsFieldType" size="mini" placeholder="请选择类型">
                <el-option label="时间类型" :value="1"></el-option>
                <el-option label="时间戳类型" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="增量时间类型格式化:" >
              <el-input   placeholder = '增量字段' v-model="form.formatType" size="mini" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
          <el-col :span="8"></el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import {datSoSel, getAutoCode, gatherUp,mockInfDetail} from "@/api/dataAcquisition";

export default {
  name: "dataCoonfigAdd",
  data(){
    return {
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: ""
      },
        {
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: "head-save-back",
          type: "button",
          icon: ""
        }, {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: ""
        }],
      rules: {
        infCode: [
          { required: true, message: '请输入接口编码', trigger: 'change' }
        ],
        infName: [
          { required: true, message: '请输入接口名称', trigger: 'change' }
        ],
        dataTable: [
          { required: true, message: '请输入表名字', trigger: 'change' }
        ],
        tsField: [
          { required: true, message: '请输入增量字段', trigger: 'change' }
        ],
        dataSource: [
          { required: true, message: '请选择数据源', trigger: 'change' }
        ],
      },
      form: {
        dataSource:'',
        dataTable:'',
        infCode:'',
        infName:'',
        tsField:'',
        tsFieldType:'',
        formatType:'',
      },
      formLabelWidth: '120px',
      selDat:[]
    }
  },
  created(){
    this.datSoSelFn();
    this.getAutoCodeFn();
    this.getMockInfDetail()
  },
  methods:{

    getMockInfDetail(){
      if(this.$route.query.type!=='edit') return
      mockInfDetail(this.$route.query.id).then(res=>{
        this.form=res.data.data
      })
    },

    headSave(type){
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if(this.form.id){
            gatherUp(this.form).then(()=>{
              this.$message.success('修改成功！');
              if(type=='back'){
                this.headCancel()
              }
            })
          }else{
            gatherUp(this.form).then(()=>{
              this.$message.success('新增成功！');
              if(type=='back'){
                this.headCancel()
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    datSoSelFn(){
      datSoSel().then((res)=>{
        this.selDat = res.data.data;
      })
    },
    getAutoCodeFn(){
      getAutoCode().then((res)=>{
        console.log(res);
        this.form.infCode = res.data.data;
      })
    },
  }
}
</script>

<style scoped>

</style>
