var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-title": "数据采集新增",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave("save")
          },
          "head-save-back": function ($event) {
            return _vm.headSave("back")
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c(
        "div",
        { staticClass: "newZtFormBox" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                inline: false,
                model: _vm.form,
                "label-width": "120px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "接口编码:", prop: "infCode" } },
                        [
                          _c("el-input", {
                            staticClass: "new-form-input",
                            attrs: {
                              disabled: "",
                              size: "mini",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.form.infCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "infCode", $$v)
                              },
                              expression: "form.infCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "接口名称:", prop: "infName" } },
                        [
                          _c("el-input", {
                            staticClass: "new-form-input",
                            attrs: {
                              placeholder: "接口名称",
                              size: "mini",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.form.infName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "infName", $$v)
                              },
                              expression: "form.infName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "表名:", prop: "dataTable" } },
                        [
                          _c("el-input", {
                            staticClass: "new-form-input",
                            attrs: {
                              placeholder: "表名",
                              size: "mini",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.form.dataTable,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "dataTable", $$v)
                              },
                              expression: "form.dataTable",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "增量字段:", prop: "tsField" } },
                        [
                          _c("el-input", {
                            staticClass: "new-form-input",
                            attrs: {
                              placeholder: "增量字段",
                              size: "mini",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.form.tsField,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "tsField", $$v)
                              },
                              expression: "form.tsField",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "数据源:", prop: "dataSource" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "new-form-input",
                              attrs: {
                                size: "mini",
                                placeholder: "请选择类型",
                              },
                              model: {
                                value: _vm.form.dataSource,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "dataSource", $$v)
                                },
                                expression: "form.dataSource",
                              },
                            },
                            _vm._l(_vm.selDat, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.sourceName,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "增量时间类型:" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "new-form-input",
                              attrs: {
                                size: "mini",
                                placeholder: "请选择类型",
                              },
                              model: {
                                value: _vm.form.tsFieldType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "tsFieldType", $$v)
                                },
                                expression: "form.tsFieldType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "时间类型", value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: "时间戳类型", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "增量时间类型格式化:" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "增量字段",
                              size: "mini",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.form.formatType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "formatType", $$v)
                              },
                              expression: "form.formatType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 8 } }),
                  _c("el-col", { attrs: { span: 8 } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }